import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef, SelectionChangedEvent, FirstDataRenderedEvent, RowDataUpdatedEvent, FilterChangedEvent, IRowNode, RowSelectionOptions, RowClassParams } from "ag-grid-enterprise";
import { SupplyType } from "../../types";
import debounce from 'lodash/debounce';
import { CEDLinkRenderer, SupplyLinkRenderer } from "../../components/CellRenderers";
import { IconButton } from "@chakra-ui/react";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { caseInsensitiveComparator, dateValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";
import { useApi } from "../../hooks/useApi";
import "../../styles/hh-checkbox-disabled.css";

type FilterPanePropTypes = {
    supplies: string[];
    onSupplyFilterChange: (supplies: string[]) => void;
    toggleFocus: boolean;
}

function AgFilterPane({ supplies, onSupplyFilterChange, toggleFocus }: FilterPanePropTypes) {
    const {ready, getSupplies} = useApi();
    const [suppliesData, setSuppliesData] = useState<SupplyType[]>([]);

    useEffect(() => {
        if (ready)
            loadSupplies();
    }, [ready, getSupplies]);

    const filterResults = (results: SupplyType[]) : SupplyType[] => {
        return results.filter((supply) => supply.supply_type !== "Water");
    } 

    async function loadSupplies() {
        const responseJson = await getSupplies();
        const filteredResults = filterResults(responseJson)
        setSuppliesData(filteredResults);
    }
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs] = useState<ColDef<SupplyType>[]>([
        { field: "account_name", headerName: "Account Name", rowGroup: true, hide: true, sort: "asc", sortIndex: 0 },
        { field: "site_group", headerName: "Site Group", rowGroup: true, hide: true, sort: "asc", sortIndex: 1 },
        { field: "site_address", headerName: "Site Address", rowGroup: true, hide: true, sort: "asc", sortIndex: 2 },
        { field: "supply_type", headerName: "Supply Type", sort: "asc", sortIndex: 3 },
        { field: "supply_subtype", headerName: "Supply Subtype", sort: "asc", sortIndex: 4 },
        { field: "supply_verbose", headerName: "Supply Number", sort: "asc", sortIndex: 5, cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },  
        { field: "supply_supplier", headerName: "Supplier" },
        { field: "contract_end_date", headerName: "Contract End Date", cellRenderer: CEDLinkRenderer, cellDataType: 'dateString', filter: 'agDateColumnFilter', minWidth: 200, filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
        { field: "eb_currentconsumption", headerName: "Current Consumption" },
        { field: "supply_number", headerName: "Supply Number Short", hide: true },
        { field: "ooc", headerName: "OOC", hide: true  }, 
        { field: "eb_meterserialnumber", headerName: "Meter Serial Number", hide: true  },
    ]);

    const gridRef = useRef<AgGridReact<SupplyType>>(null);

    const rowClassRules = {
        'hh-checkbox-disabled': (params: RowClassParams) => { return params.node?.data?.has_data === 0 },
    };
    

    const defaultColDef: ColDef = useMemo(
            () => ({
                filter: true,
                enableRowGroup: true,
                enableValue: true,
                comparator: caseInsensitiveComparator,
            }),
        []
    );

    const rowSelection = useMemo(() => { 
        return {
            mode: 'multiRow',
            selectAll: 'filtered',
            groupSelects: 'filteredDescendants',
            isRowSelectable: (node: IRowNode) => node.data?.has_data === 1 || node.group, // Disable checkbox if has_data is false
        } as RowSelectionOptions;
    }, []);

    const groupRowRendererParams = {
        // puts a checkbox onto each group row
        checkbox: true,
    };

    const debouncedSelectionChanged = useCallback(debounce((event: SelectionChangedEvent) => {
        const selection = event.api.getSelectedNodes();
        if (selection) {
            const supplies = selection.map(node => node.data.supply_number);
            onSupplyFilterChange(supplies);
        }
    }, 300), []); 

    const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
        debouncedSelectionChanged(event); // Debouncing stops propagation of multiple select events and rolls into single query
    }, [debouncedSelectionChanged]);

    const onRowDataChange = useCallback((event: FirstDataRenderedEvent | RowDataUpdatedEvent) => {
        if (supplies.length == 0) {
            event.api.selectAll();
        } else {
            event.api.forEachNode(node => {
                if (supplies.includes(node.data?.supply_number)) {
                    node.setSelected(true);
                }
            });
        }
    }, [supplies]);

    const onFilterChange = useCallback((event: FilterChangedEvent) => {
        const unfiltered: IRowNode[] = [];
        event.api.forEachNodeAfterFilter(node => {
            unfiltered.push(node.data?.supply_number);
        });
        event.api.forEachNode(node => {
            if (!node.group) {
                const selected : boolean = unfiltered.includes(node.data?.supply_number);
                node.setSelected(selected);
            }
        });
    }, []);

    const resetState = useCallback(() => {
        gridRef.current!.api.resetColumnState();
        gridRef.current!.api.setFilterModel(null);;
    }, []);

    return (
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
          >
            {toggleFocus && <IconButton backgroundColor={'#B391C3'} _hover={{ bg: "#8f749c" }} m={1} zIndex={1} position={'absolute'} icon={<FaArrowRotateLeft />} onClick={resetState} aria-label={"Reset"}>Reset</IconButton>}
            <AgGridReact<SupplyType>
                rowClassRules={rowClassRules}
                ref={gridRef}
                rowData={suppliesData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowSelection={rowSelection}
                suppressAggFuncInHeader={true}
                rowGroupPanelShow={"always"}
                groupDisplayType={'groupRows'}
                onSelectionChanged={onSelectionChanged}
                onFirstDataRendered={onRowDataChange}
                onRowDataUpdated={onRowDataChange}
                onFilterChanged={onFilterChange}
                groupRowRendererParams={groupRowRendererParams}
                groupAllowUnbalanced
            />
        </div>
    )
}
  
export default AgFilterPane;
