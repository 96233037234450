import { Box, Flex, Text, IconButton } from "@chakra-ui/react"
import { FaInfo } from "react-icons/fa"
import { FaArrowRotateLeft } from "react-icons/fa6"

function FlexInfoSelector() {
  return (
    <Flex justify={'space-between'}>
      <Box>
        <IconButton icon={<FaInfo />}  aria-label={"Contract-Info"} /> Contract Info
      </Box>
      <Box margin={'auto'}>
        <Text>MPAN: 000000 00000000000000 (Supply selection here)</Text>
      </Box>
      <Box>
        <IconButton icon={<FaArrowRotateLeft />} aria-label={"Reset"}>Reset</IconButton>
      </Box>
    </Flex>
  )
}

export default FlexInfoSelector