import { Box } from "@chakra-ui/react";
import BaseTemplate from "../components/BaseTemplate";
import FlexGraph from "./Flex/FlexGraph";
import FlexGrid from "./Flex/FlexGrid";
import FlexInfoSelector from "./Flex/FlexInfoSelector";

function Flex() {

  // use state for selected supplies (pass setSupplies to flexinfoselector and value to body componenets)

  return (
    <BaseTemplate  fitToWindow={true}>
      <BaseTemplate.Header>
        <FlexInfoSelector />
      </BaseTemplate.Header>
      <BaseTemplate.Body>
        <Box h={'50%'}>
          <FlexGraph />
        </Box>
        
        <FlexGrid />
      </BaseTemplate.Body>
    </BaseTemplate>
  )
}

export default Flex