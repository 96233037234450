import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef } from "ag-grid-enterprise";
import { SupplyType } from "../../types"
import { CEDLinkRenderer, SupplyLinkRenderer } from "../../components/CellRenderers";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { dateValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";

function FlexGrid() {
  const {ready, getSupplies} = useApi();
  const [suppliesData, setSuppliesData] = useState<SupplyType[]>([]);

  useEffect(() => {
      if (ready)
          loadSupplies();
  }, [ready, getSupplies]);

  const filterResults = (results: SupplyType[]) : SupplyType[] => {
      return results.filter((supply) => supply.supply_type !== "Water");
  } 

  async function loadSupplies() {
      const responseJson = await getSupplies();
      const filteredResults = filterResults(responseJson)
      setSuppliesData(filteredResults);
  }

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs] = useState<ColDef<SupplyType>[]>([
      { field: "account_name", headerName: "Account Name", rowGroup: true, hide: true, sort: "asc", sortIndex: 0 },
      { field: "site_group", headerName: "Site Group", hide: true, sort: "asc", sortIndex: 1 },
      { field: "site_address", headerName: "Site Address", hide: true, sort: "asc", sortIndex: 2 },
      { field: "supply_type", headerName: "Supply Type", sort: "asc", sortIndex: 3 },
      { field: "supply_subtype", headerName: "Supply Subtype", sort: "asc", sortIndex: 4 },
      { field: "supply_verbose", headerName: "Supply Number", sort: "asc", sortIndex: 5, cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },  
      { field: "supply_supplier", headerName: "Supplier" },
      { field: "contract_end_date", headerName: "Contract End Date", cellRenderer: CEDLinkRenderer, cellDataType: 'dateString', filter: 'agDateColumnFilter', minWidth: 200, filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
      { field: "eb_currentconsumption", headerName: "Current Consumption" },
      { field: "supply_number", headerName: "Supply Number Short", hide: true },
      { field: "ooc", headerName: "OOC", hide: true  }, 
      { field: "eb_meterserialnumber", headerName: "Meter Serial Number", hide: true  },
  ]);
  
  return (
    <AgGridReact<SupplyType>
        rowData={suppliesData}
        columnDefs={colDefs}
        suppressAggFuncInHeader={true}
        rowGroupPanelShow={"always"}
        groupDisplayType={'groupRows'}
        groupAllowUnbalanced
    />
  )
}

export default FlexGrid