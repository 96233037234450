import { Bar, ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

const data = [
  {
    name: 'Oct 24',
    uv: 590,
  },
  {
    name: 'Nov 24',
    uv: 868,
  },
  {
    name: 'Dec 24',
    uv: 1397,
  },
  {
    name: 'Jan 25',
    uv: 1480,
  },
  {
    name: 'Feb 25',
    uv: 1520,
  },
  {
    name: 'Mar 25',
    uv: 1400,
  },
];

function FlexGraph() {
  return (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} margin={{left: 40 }}>
              <XAxis dataKey="name" />
              <YAxis />
              <Bar type="monotone" dataKey="uv" barSize={20} fill="#413ea0" />
          </ComposedChart>
        </ResponsiveContainer>
  )
}

export default FlexGraph